<template>
    <Datatable
        name="Card authorizations"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
    >
        <template v-slot:filters>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by status" small>
                <li v-for="(avStatus, index) in statuses" :key="index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="status === index ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByStatus(index)"
                        >{{ avStatus.name }}</a
                    >
                </li>
            </Dropdown>
            <Dropdown class="me-1 mb-1" color="secondary" title="Filter by status reason" small>
                <li v-for="(rStatus, index) in reasonStatuses" :key="index">
                    <a
                        href="#"
                        class="dropdown-item text-xs"
                        :class="reasonStatus === rStatus.reason ? 'bg-primary text-white' : 'bg-white'"
                        @click.prevent="filterByReasonStatus(rStatus.reason)"
                        >{{ rStatus.name }}</a
                    >
                </li>
            </Dropdown>
            <Button
                class="me-1"
                @click="toggleFilterDoesntHavePartner"
                :color="doesntHavePartner ? 'success' : doesntHavePartner === false ? 'danger' : 'secondary'"
                small
            >
                Without partner
            </Button>
            <Button
                class="me-1"
                @click="toggleFilterWithTransactions"
                :color="withTransactions ? 'success' : withTransactions === false ? 'danger' : 'secondary'"
                small
            >
                With transactions
            </Button>
        </template>
        <template v-slot:thead>
            <tr>
                <th>Date</th>
                <th>Store</th>
                <th>User</th>
                <th>Merchant</th>
                <th>Partner</th>
                <th>Amount</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <template v-slot:default>
            <tr v-for="authorization in authorizations" @click="openAuthorization(authorization)">
                <td>{{ authorization.transaction_at | formatDate }}</td>
                <td><Store v-if="authorization.user" :id="authorization.user.store_id" /></td>
                <td>
                    <template v-if="authorization.user"><Icon icon="user" /> {{ authorization.user.name }}</template>
                </td>
                <td>
                    <div class="text-truncate" style="max-width: 200px;">
                        {{ authorization.merchant.name }} ({{ authorization.merchant.id }})
                    </div>
                </td>
                <td>
                    <Badge
                        v-if="authorization.partner"
                        color="success"
                        icon="handshake"
                        class="text-truncate"
                        style="max-width: 150px;"
                        >{{ authorization.partner.name[$store.state.lang.displayLanguage] }}</Badge
                    >
                    <Badge v-else color="secondary" icon="question-circle">Partner unknown</Badge>
                </td>
                <td>{{ authorization.amount | currency }}</td>
                <td><CardAuthorizationStatus :status="authorization.status" /></td>
                <td class="text-right"><Icon icon="angle-right" /></td>
            </tr>
        </template>
    </Datatable>
</template>

<script>
    import { Badge, Button, Datatable, Dropdown } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import CardAuthorizationStatus from '@/components/CardAuthorizations/status'
    import statuses from '@/components/CardAuthorizations/statuses'
    import Store from '@/components/layout/Store'
    import { formatBoolean } from '@/utils/query'

    export default {
        name: 'CardAuthorizations',
        components: {
            Badge,
            Button,
            CardAuthorizationStatus,
            Datatable,
            Dropdown,
            Store,
        },
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            hideActions: Badge,
        },
        data() {
            return {
                loading: true,
                search: null,
                status: null,
                reasonStatus: null,
                statuses: statuses,
                authorizations: [],
                doesntHavePartner: null,
                withTransactions: null,
                reasonStatuses: [
                    { reason: 'merchant_not_authorized', name: 'Merchant not authorized' },
                    { reason: 'merchant_country_unauthorized', name: 'Merchant country unauthorized' },
                    { reason: 'merchant_types_not_allowed', name: 'Merchant types not allowed' },
                    { reason: 'exceeding_spending_limit', name: 'Exceeding spending limit' },
                    { reason: 'not_enough_available_credits', name: 'Not enough available credits' },
                    { reason: 'no_wallet_eligible', name: 'No wallet eligible' },
                    { reason: 'issuing_webhook_timeout', name: 'Webhook timeout' },
                    { reason: 'issuing_webhook_error', name: 'Webhook error' },
                ],
                pagination: { current_page: 1 },
            }
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                // Set params
                this.loading = true
                this.search = search
                let params = this.mergeParams({
                    search: this.search,
                    statuses: [this.status],
                    page: this.pagination.current_page,
                    order_by: 'created_at',
                    sort_by: 'desc',
                    doesnt_have_partner: formatBoolean(this.doesntHavePartner),
                    with_transactions: formatBoolean(this.withTransactions),
                    status_reason: this.reasonStatus ? this.reasonStatus : undefined,
                    ...(this.params.groupId && { group: this.params.groupId }),
                    includes: ['partner', 'user', 'merchant'],
                })
                // Search function
                return this.fetchFunction(params)
                    .then((response) => {
                        this.authorizations = response.data
                        this.pagination = response.meta.pagination
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            fetchFunction(params) {
                if (this.params.partner) {
                    return this.$api.partners.getCardAuthorizations(this.params.partner, params)
                } else if (this.params.card) {
                    return this.$api.cards.getCardAuthorizations(this.params.card, params)
                } else {
                    return this.$api.cards.getAuthorizations(params)
                }
            },
            filterByStatus(status) {
                this.status = this.status === status ? null : status
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            filterByReasonStatus(reason) {
                this.reasonStatus = this.reasonStatus === reason ? null : reason
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            toggleFilterDoesntHavePartner() {
                if (this.doesntHavePartner === null) this.doesntHavePartner = true
                else if (this.doesntHavePartner === true) this.doesntHavePartner = false
                else this.doesntHavePartner = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            toggleFilterWithTransactions() {
                if (this.withTransactions === null) this.withTransactions = true
                else if (this.withTransactions === true) this.withTransactions = false
                else this.withTransactions = null
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
            openAuthorization(authorization) {
                let panel = this.openPanel('CardAuthorizationPanel', authorization.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
        },
    }
</script>
