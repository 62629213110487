<template>
    <div>
        <Datatable name="Cards" @search="fetchData" :pagination.sync="pagination" :loading="loading" :hover="true">
            <template v-slot:filters>
                <Button
                    class="me-1"
                    @click="toggleFilterCanceled(0)"
                    :color="cardIsCanceled === 0 ? 'success' : 'secondary'"
                    small
                >
                    Active
                </Button>
                <Button
                    class="me-1"
                    @click="toggleFilterCanceled(1)"
                    :color="cardIsCanceled === 1 ? 'success' : 'secondary'"
                    small
                >
                    All
                </Button>
            </template>
            <template v-slot:thead>
                <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Number</th>
                    <th>Expiry</th>
                    <th>Currency</th>
                    <th>Name on card</th>
                    <th>Issued by</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </template>
            <tr v-for="(card, index) in cards" @click="openCard(card)" :key="'card-' + index">
                <td>
                    <code>{{ card.id }}</code>
                </td>
                <td>
                    <Badge class="text-capitalize">{{ card.type }}</Badge>
                </td>
                <td><Icon icon="credit-card" /> •••• {{ card.last_four }}</td>
                <td>{{ card.expiry }}</td>
                <td>{{ card.currency }}</td>
                <td>{{ card.name_on_card }}</td>
                <td>
                    <span v-if="card.issuer"><Icon icon="building" /> {{ card.issuer.name }}</span>
                </td>
                <td><CardStatus :card="card" /></td>
                <td><Icon icon="angle-right" /></td>
            </tr>
        </Datatable>
    </div>
</template>

<script>
    import { Datatable, Badge, Boolean, Button, Alerts } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import CardStatus from '@/components/Cards/status.vue'

    export default {
        name: 'Cards',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            wallet: {
                type: Number,
            },
        },
        data() {
            return {
                loading: false,
                search: null,
                pagination: {
                    current_page: 1,
                },
                cards: [],
                cardIsCanceled: 0,
            }
        },
        components: {
            Datatable,
            Badge,
            Boolean,
            Button,
            CardStatus,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.search = search
                if (this.params?.cards?.length > 0) {
                    this.cards = this.params.cards
                    this.loading = false
                } else {
                    this.fetchFunction(
                        this.mergeParams({
                            search: this.search,
                            page: this.pagination.current_page,
                            with_cancelled: this.cardIsCanceled ? 1 : undefined,
                            includes: ['issuer'],
                            order_by: 'created_at',
                            sort_by: 'desc',
                        })
                    )
                        .then((response) => {
                            this.cards = response.data
                            this.pagination = response.meta.pagination
                        })
                        .catch((error) => {
                            if (error && error.response) Alerts.notificationError(error.response.data.message)
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            },
            fetchFunction(params) {
                if (this.params.user) {
                    return this.$api.users.getCards(this.params.user, params)
                } else if (this.params.group) {
                    return this.$api.groups.getCards(this.params.group, params)
                } else if (this.wallet) {
                    return this.$api.wallets.getCards(this.wallet, params)
                } else {
                    return this.$api.cards.get(params)
                }
            },
            openCard(card) {
                let panel = this.openPanel('CardPanel', card.id)
                panel.promise.then((result) => {
                    if (result && result.refresh) {
                        this.fetchData(this.search)
                    }
                })
            },
            openGroup(group) {
                this.openPanel('GroupPanel', group.id)
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            toggleFilterCanceled(value) {
                if (this.cardIsCanceled === value) {
                    this.cardIsCanceled = null
                } else {
                    this.cardIsCanceled = value
                }
                this.pagination.current_page = 1
                this.fetchData(this.search)
            },
        },
    }
</script>
