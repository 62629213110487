import Error404 from '@tech_hexeko/base-front/src/components/Errors/404.vue';

export default [
    {
        path: '/',
        component: require('./components/Dashboard/index').default,
        name: 'dashboard',
    },
    {
        path: '/users',
        component: require('./components/layout/Tabs/Users').default,
        children: [
            {
                path: '',
                component: require('./components/Users').default,
                name: 'users',
            },
            {
                path: '/groups',
                component: require('./components/Groups/index').default,
                name: 'groups',
            },
            {
                path: 'wallets',
                component: require('./components/Wallets').default,
                name: 'wallets',
            },
        ],
    },
    {
        path: '/cards',
        component: require('./components/layout/Tabs/Cards').default,
        children: [
            {
                path: '',
                component: require('./components/Cards').default,
                name: 'cards',
            },
            {
                path: 'authorizations',
                component: require('./components/CardAuthorizations').default,
                name: 'card-authorizations',
            },
            {
                path: 'transactions',
                component: require('./components/CardTransactions').default,
                name: 'card-transactions',
            },
        ],
    },
    {
        path: '/bookings',
        component: require('./components/layout/Tabs/Bookings').default,
        children: [
            {
                path: '',
                component: require('./components/Bookings').default,
                name: 'bookings',
            },
            {
                path: 'requests',
                component: require('./components/BookingRequests').default,
                name: 'booking-requests',
            },
        ],
    },
    {
        path: '/expenses',
        component: require('./components/layout/Tabs/Expenses').default,
        children: [
            {
                path: '',
                component: require('./components/Expenses').default,
                name: 'expenses',
            },
            {
                path: 'requests',
                component: require('./components/ExpenseRequests').default,
                name: 'expense-requests',
            },
            {
                path: 'requests/:id',
                component: require('./components/ExpenseRequests').default,
                name: 'expense-request',
            },
            {
                path: 'allowances',
                component: require('./components/Allowances').default,
                name: 'allowances',
            },
            {
                path: 'payment-intents',
                component: require('./components/PaymentIntents').default,
                name: 'payment-intents',
            },
            {
                path: 'payment-transactions',
                component: require('./components/PaymentTransactions').default,
                name: 'payment-transactions',
            },
        ],
    },
    {
        path: '/partners',
        component: require('./components/layout/Tabs/Partners').default,
        children: [
            {
                path: '',
                component: require('./components/Partners').default,
                name: 'partners',
            },
            {
                path: 'types',
                component: require('./components/Types').default,
                name: 'types',
            },
        ],
    },
    {
        path: '/vouchers',
        component: require('./components/VoucherLists/index').default,
        name: 'vouchers',
    },
    {
        path: '/payouts',
        component: require('./components/layout/Tabs/Payouts').default,
        children: [
            {
                path: '',
                component: require('./components/Payouts/index').default,
                name: 'payouts',
            },
            {
                path: 'requests',
                component: require('./components/PayoutRequests/index').default,
                name: 'payout-requests',
            },
        ],
    },
    {
        path: '/billing',
        component: require('./components/layout/Tabs/Billing').default,
        name: 'billing',
        children: [
            {
                path: 'quotes',
                component: require('./components/Quotes/index').default,
                name: 'quotes',
            },
            {
                path: 'invoices',
                component: require('./components/Invoices/index').default,
                name: 'invoices',
            },
            {
                path: 'credit-notes',
                component: require('./components/CreditNotes').default,
                name: 'credit-notes',
            },
            {
                path: 'accounts',
                component: require('./components/BillingAccounts/index').default,
                name: 'accounts',
            },
            {
                path: 'products',
                component: require('./components/Products/index').default,
                name: 'products',
            },
        ],
    },
    {
        path: '/config',
        component: require('./components/Config/index').default,
        name: 'config',
        children: [
            {
                path: 'stores',
                component: require('./components/Stores/index').default,
                name: 'stores',
            },
            {
                path: 'roles',
                component: require('./components/Roles/index').default,
                name: 'roles',
            },
        ],
    },
    {
        path: '*',
        name: '404',
        component: Error404,
        meta: {
            fullscreen: true,
            requiresAuth: false,
        },
    },
];
