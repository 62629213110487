import { base, store } from '@tech_hexeko/base-front';
import { api, verificationApi, exporterApi, importerApi } from '@tech_hexeko/hexeko-api-classes';
import VueSlideoutPanel from 'vue2-slideout-panel';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import Panels from './mixins/Panels';
import UserPanel from '@/components/Users/show';
import OfferPanel from '@/components/Offers/show';
import BookingPanel from '@/components/Bookings/show';
import ExpenseRequestPanel from '@/components/ExpenseRequests/show';
import ExpensePanel from '@/components/Expenses/show';
import BookingRequestPanel from '@/components/BookingRequests/show';
import PaymentMethodPanel from '@/components/PaymentMethods/show';
import GroupPanel from '@/components/Groups/show';
import PartnerPanel from '@/components/Partners/show';
import PaymentIntentPanel from '@/components/PaymentIntents/show';
import WalletPanel from '@/components/Wallets/show';
import CardPanel from '@/components/Cards/show';
import TokenPanel from '@/components/Cards/showToken';
import ActivityPanel from '@/components/Activities/show';
import StorePanel from '@/components/Stores/show';
import RolePanel from '@/components/Roles/show';
import VoucherListPanel from '@/components/VoucherLists/show';
import VoucherPanel from '@/components/Vouchers/show';
import TypePanel from '@/components/Types/show';
import PaymentTransactionPanel from '@/components/PaymentTransactions/show';
import BulkActionsPanel from '@/components/layout/BulkActions';
import CardTransactionPanel from '@/components/CardTransactions/show';
import CardAuthorizationPanel from '@/components/CardAuthorizations/show';
import InvoicePanel from '@/components/Invoices/show';
import CreditNotePanel from '@/components/CreditNotes/show';
import QuotePanel from '@/components/Quotes/show';
import BillingAccountPanel from '@/components/BillingAccounts/show';
import AllowancePanel from '@/components/Allowances/show';
import PayoutPanel from '@/components/Payouts/show';
import PayoutRequestPanel from '@/components/PayoutRequests/show';
import ErrorHandling from './plugins/errors';
import routes from './routes';
import adminStore from './store';
import translations from './lang';
import userTranslations from '@tech_hexeko/hexeko-user-account/lang';

export default {
    install(Vue, payload) {
        // Default options
        const options = {
            name: 'Hexeko Admin',
            routes: routes,
            api: {
                baseURL: process.env.VUE_APP_API_URL,
            },
            exporterApi: {
                baseURL: process.env.VUE_APP_EXPORTER_API_URL,
            },
            importerApi: {
                baseURL: process.env.VUE_APP_IMPORTER_API_URL,
            },
            verificationApi: {
                baseURL: process.env.VUE_APP_VERIFICATION_API_URL,
            },
            log: {
                rollbarToken: '27529810e21248c58a641c252f6c38a3',
                enabled: process.env.NODE_ENV === 'production',
            },
        };

        // Router
        let router = payload.router;

        // Extend store
        store.registerModule('admin', adminStore);

        // Intercept errors
        Vue.use(ErrorHandling, { apis: [api, verificationApi, exporterApi, importerApi], router });

        // Hexeko Base Front
        Vue.use(base, options);
        base.setLanguage('en');

        // Panel
        Vue.use(VueSlideoutPanel);

        // Numeral
        Vue.use(vueNumeralFilterInstaller, { locale: 'fr' });

        // Global mixins
        Vue.mixin(Panels);

        // Importing main component to the app
        Vue.component('App', require('./components/App').default);

        // Auto-loaded components
        Vue.component('UserPanel', UserPanel);
        Vue.component('BookingPanel', BookingPanel);
        Vue.component('OfferPanel', OfferPanel);
        Vue.component('PayoutPanel', PayoutPanel);
        Vue.component('PayoutRequestPanel', PayoutRequestPanel);
        Vue.component('GroupPanel', GroupPanel);
        Vue.component('PartnerPanel', PartnerPanel);
        Vue.component('PaymentIntentPanel', PaymentIntentPanel);
        Vue.component('WalletPanel', WalletPanel);
        Vue.component('CardPanel', CardPanel);
        Vue.component('TokenPanel', TokenPanel);
        Vue.component('ActivityPanel', ActivityPanel);
        Vue.component('StorePanel', StorePanel);
        Vue.component('RolePanel', RolePanel);
        Vue.component('VoucherListPanel', VoucherListPanel);
        Vue.component('VoucherPanel', VoucherPanel);
        Vue.component('ExpenseRequestPanel', ExpenseRequestPanel);
        Vue.component('ExpensePanel', ExpensePanel);
        Vue.component('BookingRequestPanel', BookingRequestPanel);
        Vue.component('PaymentMethodPanel', PaymentMethodPanel);
        Vue.component('PaymentTransactionPanel', PaymentTransactionPanel);
        Vue.component('TypePanel', TypePanel);
        Vue.component('CardTransactionPanel', CardTransactionPanel);
        Vue.component('CardAuthorizationPanel', CardAuthorizationPanel);
        Vue.component('BulkActionsPanel', BulkActionsPanel);
        Vue.component('InvoicePanel', InvoicePanel);
        Vue.component('CreditNotePanel', CreditNotePanel);
        Vue.component('QuotePanel', QuotePanel);
        Vue.component('BillingAccountPanel', BillingAccountPanel);
        Vue.component('AllowancePanel', AllowancePanel);

        // Add translations
        store.dispatch('lang/addTranslations', translations);
        store.dispatch('lang/addTranslations', userTranslations);

        // Languages
        store.dispatch('lang/setAvailableLanguages', ['fr', 'nl', 'en']);
        store.dispatch('lang/setLanguage', 'en');
    },
};
