<template>
    <FormRoot :loading="loading">
        <Alert color="info" icon="info-circle">User should be part of at least one group allowing issued cards.</Alert>
        <Alert v-if="groups.length === 0">User has no groups.</Alert>
        <Field
            v-else
            :form.sync="form"
            type="radio"
            name="group"
            :options="groups"
            optionsKeyBy="id"
            optionsLabel="name"
            placeholder="Group"
            hint="Which group is issuing cards for the user ?"
            @input="clearErrors"
        />

        <template v-if="form.group">
            <Field
                :form.sync="form"
                type="radio"
                name="type"
                placeholder="Type"
                hint="Type of the card"
                :options="{ virtual: 'Virtual card', physical: 'Physical card' }"
                @input="clearErrors"
            />
            <FieldsCard
                v-if="isPhysical"
                :form.sync="form"
                @encryptPin="handleEncryptPin"
                @generatePin="handleGeneratePin"
                @resetEncryptedPin="resetEncryptedPin"
                @resetPin="handleResetPin"
            />
            <FormActions>
                <Submit class="float-right" :disabled="disableSubmit" @click="onSubmit">Issue card</Submit>
            </FormActions>
        </template>
    </FormRoot>
</template>

<script>
    import { Alert, Field, FormActions, FormRoot, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Generator from '@/mixins/Generator'
    import FieldsCard from '@/components/layout/FieldsCard'

    export default {
        mixins: [Forms, Generator],
        components: {
            Alert,
            Field,
            FieldsCard,
            FormActions,
            FormRoot,
            Submit,
        },
        data() {
            return {
                groups: [],
                fields: {
                    group: null,
                    pin: null,
                    encrypted_pin: null,
                    random_pin: false,
                    shipping_address_source: null,
                    shipping_delivery_service: null,
                    shipping_delivery_type: null,
                    type: null,
                },
                messageSuccess: 'The card will be created soon',
                loading: false,
            }
        },
        props: {
            user: {
                type: Number,
                required: false,
            },
        },
        computed: {
            disableSubmit() {
                return !this.form.group || !this.form.type || (this.isPhysical && !this.form.encrypted_pin)
            },
            isPhysical() {
                return this.form.type === 'physical'
            },
        },
        methods: {
            clearErrors() {
                this.form.errors.clear()
            },
            fetchGroups() {
                this.loading = true
                this.$api.users
                    .show(this.user, { includes: ['groups'] })
                    .then((response) => {
                        this.groups = response.groups
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            handleEncryptPin() {
                this.encryptPin(this.form.pin).then((encrypted_pin) => {
                    this.form.encrypted_pin = encrypted_pin
                })
            },
            handleGeneratePin() {
                this.resetEncryptedPin()
                this.form.pin = this.generatePin()
            },
            handleResetPin() {
                this.form.pin = null
                this.resetEncryptedPin()
            },
            resetEncryptedPin() {
                this.form.encrypted_pin = null
            },
            submit() {
                let data = this.$data.form.notNullData()
                if (data) delete data.pin
                return this.$api.users.createCard(this.user, data)
            },
        },
        mounted() {
            this.form.source = 'user'
            this.fetchGroups()
        },
    }
</script>
