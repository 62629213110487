<template>
    <Panel title="Allowance" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="allowance">
            <!-- Details -->
            <Tab name="Details" :selected="true">
                <AllowanceDetails :allowance="allowance" />
            </Tab>

            <!-- Payment intents -->
            <Tab name="Payment intents">
                <PaymentIntents :params="{ payable_type: 'allowance', payable_id: allowance.id }" />
            </Tab>

            <!-- Payout Requests -->
            <Tab name="Payouts" v-if="$store.getters.userHasPermission('showPayouts')">
                <PayoutRequests :params="{ source_type: 'allowance', source_id: allowance.id }" />
            </Tab>

            <!-- History -->
            <Tab name="History">
                <History :statuses="allowance.statuses" v-slot:default="slotProps">
                    <Badge>{{ slotProps.status.name }}</Badge>
                </History>
            </Tab>

            <!-- All allowances from the same User -->
            <Tab v-if="allowance.user && allowance.user.id" name="Other allowances">
                <Allowances :params="{ user: allowance.user.id }" hideActions />
            </Tab>
        </Tabs>

        <ID v-if="allowance && allowance.id" class="mt-4" name="Hexeko" :value="allowance.id" />

        <!-- Actions -->
        <template v-slot:actions v-if="$store.getters.userHasPermission('manageAllowances') && !loading">
            <Dropdown v-if="hasActionsAvailable" small title="Actions" position="right">
                <DropdownItem
                    v-if="isConfirmable"
                    class="text-success"
                    title="Confirm allowance"
                    text="Do you really want to confirm this allowance?"
                    confirm
                    @confirm="confirm"
                    icon="check-circle"
                    >Confirm</DropdownItem
                >
                <DropdownItem v-if="isDeclinable" class="text-danger" @click="decline" icon="stop-circle"
                    >Decline</DropdownItem
                >
                <DropdownItem
                    v-if="isCancellable"
                    class="text-danger"
                    confirm
                    @confirm="cancel"
                    title="Cancel allowance"
                    icon="times-circle"
                    text="Do you really want to cancel this allowance?"
                    >Cancel</DropdownItem
                >
            </Dropdown>

            <!-- Set status -->
            <Modal v-if="isModalOpen('setStatus')" @close="stopSetStatus" :title="`Set status to: ${newStatus}`">
                <SetStatus :status="newStatus" :allowance="allowance" @submitted="statusSubmitted" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import { Alerts, Badge, Dropdown, DropdownItem, ID, Modal, Panel, Tab, Tabs } from '@tech_hexeko/design-system'
    import AllowanceDetails from '@/components/Allowances/Details'
    import Allowances from '@/components/Allowances'
    import History from '@/components/layout/History'
    import SetStatus from './SetStatus'
    import PaymentIntents from '@/components/PaymentIntents'
    import PayoutRequests from '@/components/PayoutRequests'
    export default {
        name: 'AllowancePanel',
        mixins: [Modals],
        components: {
            PaymentIntents,
            AllowanceDetails,
            Allowances,
            Badge,
            Dropdown,
            DropdownItem,
            History,
            ID,
            Modal,
            Panel,
            SetStatus,
            PayoutRequests,
            Tab,
            Tabs,
        },
        props: {
            id: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                allowance: null,
                loading: true,
                newStatus: null,
                updated: false,
            }
        },
        computed: {
            hasActionsAvailable() {
                return this.isPending || this.isCancellable || this.isConfirmable || this.isDeclinable
            },
            isPending() {
                return this.status === 'pending'
            },
            isCancellable() {
                return !['confirmed', 'declined', 'cancelled', 'processed', 'partially_processed'].includes(this.status)
            },
            isDeclinable() {
                return this.isPending || ['scheduled', 'in_progress'].includes(this.status)
            },
            isConfirmable() {
                return this.isPending || this.status === 'declined'
            },
            status() {
                return this.allowance?.status?.name
            },
        },
        methods: {
            cancel() {
                this.$api.allowances
                    .cancel(this.id)
                    .then(() => {
                        Alerts.notificationSuccess('The allowance has been cancelled')
                        this.updated = true
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to cancel the allowance'
                        Alerts.notificationError(errorTxt)
                    })
            },
            confirm() {
                this.$api.allowances
                    .confirm(this.id)
                    .then(() => {
                        Alerts.notificationSuccess('The allowance has been confirmed')
                        this.updated = true
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response && error.response.data
                                ? error.response.data.message
                                : 'An error occurred while trying to confirm the allowance'
                        Alerts.notificationError(errorTxt)
                    })
            },
            closePanel() {
                this.$emit('closePanel', { refresh: this.updated })
            },
            decline() {
                this.startSetStatus('decline')
            },
            fetchData() {
                this.loading = true
                // Load Allowance
                this.$api.allowances
                    .show(this.id, {
                        includes: ['medias', 'payout_requests', 'selected_payment_methods', 'statuses', 'user'],
                    })
                    .then((allowance) => {
                        this.allowance = allowance
                    })
                    .catch((error) => {
                        const errorTxt =
                            error && error.response && error.response.data && error.response.data.message
                                ? error.response.data.message
                                : 'An error occurred while trying to fetch the Allowance'
                        Alerts.notificationError(errorTxt)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            startSetStatus(status) {
                this.newStatus = status
                this.openModal('setStatus')
            },
            statusSubmitted() {
                this.updated = true
                this.stopSetStatus()
                this.fetchData()
                this.closePanel()
            },
            stopSetStatus() {
                this.newStatus = null
                this.closeModal('setStatus')
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>
