<template>
    <Datatable
        name="Licenses"
        @search="fetchData"
        :pagination.sync="pagination"
        :loading="loading"
        :hideActions="hideActions"
        :hover="false"
    >
        <template v-slot:thead>
            <tr>
                <th>Reference</th>
                <th>Type</th>
                <th>Paid by</th>
                <th>Paid for</th>
                <th>Created at</th>
                <th>Used at</th>
                <th>Status</th>
                <th></th>
            </tr>
        </template>
        <tr v-for="(license, index) in licenses" valign="middle" :key="'license-' + index">
            <td>{{ license.reference }}</td>
            <td>{{ license.license_type }}</td>
            <td>{{ license.paid_by && license.paid_by.name ? license.paid_by.name : license.paid_by_id }}</td>
            <td>{{ license.paid_for && license.paid_for.name ? license.paid_for.name : license.paid_for_id }}</td>
            <td>{{ license.created_at | formatDate }}</td>
            <td>{{ license.used_at | formatDate }}</td>
            <td><InvoiceStatus :license="license"></InvoiceStatus></td>
            <td class="text-end" v-if="$store.getters.userHasPermission('managesLicenses')">
                <Spinner v-if="loadingDelete" />
                <a
                    href="#"
                    @click.prevent
                    is="confirm"
                    tag="a"
                    class="text-danger"
                    @confirm="deleteLicense(license.id)"
                    title="Are you sure you want to delete ?"
                    v-else
                >
                    <Icon icon="trash-alt" />
                </a>
            </td>
        </tr>
    </Datatable>
</template>

<script>
    import { Datatable, Boolean, Alerts, Spinner } from '@tech_hexeko/design-system'
    import Helpers from '@tech_hexeko/hexeko-api-classes/Helpers'
    import InvoiceStatus from './status'

    export default {
        name: 'Licenses',
        props: {
            params: {
                type: Object,
                default: () => ({}),
            },
            user: Number,
            hideActions: Boolean,
        },
        data() {
            return {
                loading: true,
                loadingDelete: false,
                pagination: {
                    current_page: 1,
                },
                licenses: [],
            }
        },
        components: {
            Datatable,
            Spinner,
            InvoiceStatus,
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData(search) {
                this.loading = true
                this.$api.users
                    .getLicenses(
                        this.user,
                        this.mergeParams({
                            search: search,
                            includes: ['paid_for', 'paid_by'],
                            page: this.pagination.current_page,
                        })
                    )
                    .then((response) => {
                        this.licenses = response.data
                        this.pagination = response.meta.pagination
                        this.loading = false
                    })
            },
            mergeParams(params) {
                return Helpers.cleanObject(Object.assign(this.params, params))
            },
            deleteLicense(id) {
                this.loadingDelete = true
                this.$api.licenses
                    .delete(id)
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.pagination.current_page = 1
                        this.loadingDelete = false
                        this.fetchData()
                        Alerts.notificationSuccess('License deleted')
                    })
            },
        },
    }
</script>
