<template>
    <FormRoot v-if="cardId">
        <!-- Data -->
        <Field
            :form.sync="form"
            type="radio"
            name="reason"
            :options="cancellationReasonOptions"
            optionsKeyBy="name"
            optionsLabel="name"
            hint="The reason for the stopping"
        />
        <!-- Actions -->
        <Alert color="info" icon="info-circle">
            The card will be canceled permanently.
        </Alert>
        <FormActions>
            <Submit class="float-right" :disabled="!form.submittable" :loading="form.loading" @click="onSubmit">
                Stop card
            </Submit>
        </FormActions>
    </FormRoot>
    <Alert v-else color="warning" icon="exclamation-triangle">
        No card ID was provided.
    </Alert>
</template>

<script>
    import { Alert, Field, FormActions, FormRoot, Submit } from '@tech_hexeko/design-system'
    import Forms from '@/mixins/Forms'
    import Generator from '@/mixins/Generator'

    export default {
        name: 'StopCard',
        components: {
            Alert,
            Field,
            FormActions,
            FormRoot,
            Submit,
        },
        mixins: [Forms, Generator],
        data() {
            return {
                cancellationReasonOptions: {
                    lost: 'Lost',
                    stolen: 'Stolen',
                },
                fields: {
                    reason: null,
                },
                messageSuccess: 'The card has been stopped',
            }
        },
        props: {
            cardId: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        computed: {
            isPhysical() {
                return this.type === 'physical'
            },
        },
        methods: {
            submit() {
                const data = this.$data.form.data()
                return this.$api.cards.stop(this.cardId, data)
            },
        },
    }
</script>
