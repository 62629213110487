import { Alerts } from '@tech_hexeko/design-system';
import { Auth } from 'aws-amplify';
import Swal from 'sweetalert2';

let isRefreshing = undefined;

export default {
    install(Vue, { apis, router }) {
        if (apis.length == 0) return console.error('No api provided');
        // Set variables
        this.router = router;
        this.countNetworkErrors = 0;

        // Interceptor: Response
        apis.forEach((api) => {
            api.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (!error.request) {
                        // Request was cancelled
                        console.log('Request is cancelled');
                        return Promise.reject(error);
                    }

                    if (!error.response) {
                        // Network error
                        console.log('Network error');
                        this.networkError(api, error);
                        return Promise.reject(error);
                    }

                    switch (error.response.status) {
                        case 401:
                        case 500:
                            console.log('401/500  Unauthenticated - trying to refresh');
                            // Solution temporaire en attendant que l'API nous renvoient une vrais 401
                            if (error.response.status === 500 && error.response.data.message != 'Unauthenticated')
                                return Promise.reject(error);
                            return this.refreshAndRetry(api, error)
                                .then((response) => response)
                                .catch((innerError) => {
                                    this.logout();
                                    return Promise.reject(innerError);
                                });
                        case 403:
                            console.log('403 Unauthorized');
                            this.unauthorizedError();
                            break;

                        case 404:
                            console.log('404 Page not found');
                            this.pageNotFoundError();
                            break;

                        case 422:
                            console.log('422 Validation error');
                            this.validationError(error.response.data.message);
                            break;

                        case 503:
                            console.log('Service unavailable');
                            this.networkError(api, error);
                            break;
                    }
                    return Promise.reject(error);
                }
            );
        });

        // Catch all errors
        Vue.config.errorHandler = (err) => {
            // Don't log Navigation Duplicated
            if (err.name === 'NavigationDuplicated') {
                return false;
            }
            throw err;
        };
    },

    unauthorizedError() {
        Alerts.notificationError('This action is unauthorized');
    },

    validationError(message) {
        Alerts.notificationError(message);
    },

    async logout() {
        try {
            await Auth.signOut({ global: true });
            this.router.push('/');
            return true;
        } catch (error) {
            console.log('error signing out: ', error);
            return false;
        }
    },

    pageNotFoundError() {
        Alerts.notificationError('The requested resource is not found');
    },

    networkError(api, error) {
        this.countNetworkErrors++;
        let seconds = this.countNetworkErrors * this.countNetworkErrors * 2;
        let timerInterval;

        return Alerts.askConfirmation({
            title: 'Network error',
            html: 'Retrying in <b></b> second(s)',
            cancelButtonText: 'Reload now',
            showCancelButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: seconds * 1000,
            didOpen: () => {
                Swal.showLoading();
                timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                        const b = content.querySelector('b');
                        if (b) {
                            b.textContent = Math.round(Swal.getTimerLeft() / 1000) + 1;
                        }
                    }
                }, 100);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((response) => {
            // Reload now
            if (response.isDismissed) {
                return this.retryRequest(api, error);
            }
        });
    },

    retryRequest(api, error) {
        return api.request(error.config);
    },

    async refreshAndRetry(api, error) {
        // If request has already been retried, throw error
        const { config } = error;
        if (!config || config.retried) throw error;

        // Try to refresh token
        try {
            // Ask to refresh token only once at a time
            if (!isRefreshing) isRefreshing = this.getSessionOrRefresh();
            const session = await isRefreshing;
            if (session && session.accessToken) {
                api.setToken(session.accessToken.jwtToken, 'Bearer');
            } else {
                throw error;
            }

            // Retry request
            config.retried = true;
            config.headers['Authorization'] = `Bearer ${session.accessToken.jwtToken}`;
            return api.request(config);
        } finally {
            isRefreshing = undefined;
        }
    },

    async getSessionOrRefresh() {
        // Get cached session or refresh token if expired
        return await Auth.currentSession();
    },
};
